<template>
  <div
    class="material-sensitivity px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Material Sensitivity" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            There are many uses for knowing the sensitivity of a material to
            impact, friction or electrostatic discharge (ESD). These purposes
            include:
          </p>
          <ul>
            <li>
              Evaluation of material sensitivity over time (does sensitivity
              increase or decrease).
            </li>
            <li>
              Comparison of sensitivity of two materials to determine if one
              material could replace another in a particular process.
            </li>
            <li>
              Determination of margins of safety required for certain processes.
            </li>
            <li>
              Determination of the probability of initiation or risks associated
              with the manufacture of a material.
            </li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Friction Testing" />
        <Paragraph class="fusion-text"
          ><p>
            The ABL™ and BAM Friction Tests are the two primary tests of this
            kind. Both methods are UN listed, however, the ABL Friction Test™
            has been calibrated into engineering units, which enables analytical
            comparison of results. The BAM test is good for relative comparisons
            of material reactivity only.
          </p>
          <p>
            We are able to test solid or liquid explosives at various velocities
            and at various pressures. We are able to test materials for friction
            sensitivity at ambient, cold or heated conditions. We also can test
            for sensitivity to friction at different humidities.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Friction Test (ABL™)
          </h4>
          <p>
            In the ABL Friction Test™, the sample is placed on the anvil, and a
            known force is applied hydraulically through a stationary wheel. A
            pendulum or motor drive is used to propel the sliding anvil at any
            of several standard velocities perpendicular to the force vector.
            Sample initiation is detected by visual means (spark or flame).<br />
            Reference: The Transport of Dangerous Goods: Tests and Criteria,
            second edition, United Nations Test Method 3 (b) (iii) page 108.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
          >
            Friction Test (BAM)
          </h4>
          <p>
            The sample is placed on a rough ceramic plate and a force is loaded
            on the sample through a stationary ceramic pin, in contact with the
            plate. The plate is motor driven through a complete cycle pass
            beneath the pin. The test sample is subjected to the friction
            created by the rubbing of the pin against the plate. This method
            allows for ambient temperature tests of powders and pastes.<br />
            Reference: The Transport of Dangerous Goods: Tests and Criteria,
            second edition, United Nations Test Method 3 (b) (I) page 101.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Impact Test (Modified Bureau of Mines)" />
        <Paragraph class="fusion-text"
          ><p>
            <router-link
              class="link"
              title="MBOM, Type 12 and BOE Impact"
              to="/test-equipment/mbom-type-12-and-boe-impact"
              >Impact Tests</router-link
            >
            are used to determine the response of an explosive when it is
            impacted by a moving mass. This test simulates impact conditions in
            processing operations, wherein an explosive is subjected to a
            collision between moving components of the processing equipment, by
            normal handling operations, or by the inadvertent dropping of tools
            or equipment.
          </p>
          <p>
            Two different impact tests are routinely performed. The Modified
            Bureau of Mines Test is used to obtain data in engineering units.
            The Bureau of Explosives Test is performed in order determine a DOT
            classification for the material tested (See DOT Classification.)
            Large scale impact tests can also be conducted. SMS is able to test
            samples at various thicknesses (both solids and liquids) and at
            ambient, hot or cold temperatures or at specified humidities.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated my-4"
            style="color:black;"
          >
            Impact Test (Modified Bureau of Mines)
          </h4>
          <p>
            The
            <router-link
              class="link"
              title="MBOM, Type 12 and BOE Impact"
              to="/test-equipment/mbom-type-12-and-boe-impact"
              >Modified Bureau of Mines Test™</router-link
            >
            uses a 2-kg drop weight. The sample is placed on a fixed anvil. A
            hammer of known contact area is positioned above the sample and the
            weight is raised to a predetermined height and dropped. Normally,
            sample initiation is detected by audible or visual means or by
            infrared analysis of selected decomposition products. This is the
            preferred test apparatus and method for impact testing because the
            results are obtainable in engineering units, which enable analytical
            comparison and easy ranking of materials for sensitivity.<br />
            Reference: Allegheny Ballistics Laboratory and the Bureau of Mines.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Electrostatic Discharge (ESD) Testing" />
        <Paragraph
          ><p>
            <router-link
              class="link"
              title="ABL ESD"
              to="/test-equipment/abl-esd"
              >ESD Testing</router-link
            >
            is used to determine the response of an explosive when subjected to
            various levels of electrostatic discharge energy. Electrostatic
            energy stored in a charged capacitor is discharged to the test
            sample. The sample to be tested is placed on a special holder that
            assures the electrostatic discharge will pass through the sample. A
            capacitor is charged with a known volt potential (usually 5000
            volts). The discharge needle is lowered until a spark is drawn
            through the sample. The approaching needle method is most commonly
            used because it best models the safety issues involved with ESD
            sensitivity. An infrared analyzer is normally used to determine
            sample initiation.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Evaluation Techniques" />
        <Paragraph
          ><p>
            For the purposes listed above, it is most beneficial if the data
            obtained from sensitivity testing are in engineering units. Although
            it is important to know if a material is more or less sensitive than
            another, much more value is given to the test data if they can be
            compared quantitatively. If the sensitivity of a material has
            changed over time, it is important to know by how much. This is
            especially true if there are criteria of performance to be met. In
            addition, if the data obtained from these tests is in engineering
            units, they can be readily compared to the potential energy stimulus
            that can be applied to a hazardous material during normal or
            abnormal operations. For this reason, we recommend testing using the
            ABL ESD machine, the modified BOM impact machine and the ABL
            friction machine. Learn more about our
            <router-link
              class="link"
              title="Statistical Evaluation"
              to="/resources/statistical-evaluation"
              >Evaluation Techniques</router-link
            >.
          </p>
          <p>
            Both threshold initiation levels (TILs) and PROBIT analyses can be
            determined for materials using the recommended ESD, Impact and
            Friction Tests.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated my-4"
            style="color:black;"
          >
            Threshold Initiation Level Determination
          </h4>
          <p>
            For the purposes listed above, it is most beneficial if the data
            obtained from sensitivity testing are in engineering units. Although
            it is important to know if a material is more or less sensitive than
            another, much more value is given to the test data if they can be
            compared quantitatively. If the sensitivity of a material has
            changed over time, it is important to know by how much. This is
            especially true if there are criteria of performance to be met.
            Also, if the data obtained from these tests is in engineering units,
            they can be readily compared to the potential energy stimulus that
            can be applied to a hazardous material during normal or abnormal
            operations. For this reason, we recommend testing using the ABL ESD
            machine, the modified BOM impact machine and the ABL friction
            machine.
          </p>
          <h4
            data-fontsize="13"
            data-lineheight="19.5px"
            class="fusion-responsive-typography-calculated my-4"
            style="color:black;"
          >
            Probit Analysis
          </h4>
          <p>
            Once the TIL for a substance has been determined, additional trials
            can be performed within the data range of the TIL, to provide
            sufficient data for a PROBIT analysis. At least 10 trials are
            performed at each of five different energy levels above the TIL. The
            data received from these trials are statistically analyzed. The
            resulting PROBIT curve is given as the probability of initiation
            versus the energy input. The results are used to calculate margins
            of safety and the probability of initiation of the material from the
            tested stimuli. The curve obtained from the PROBIT is a more
            accurate representation of the reactivity of the material than the
            TIL alone. The value of PROBIT data far exceeds the effort.
          </p>
          <p>
            The PROBIT information is normally used in conjunction with an
            analysis of the In-Process Potential (IPP). The IPP is the potential
            stimulus that can be applied to a hazardous material during normal
            or abnormal process conditions. The calculated stimulus input and
            corresponding probability of initiation is located on the curve.
            This value is used directly in a hazards analysis.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Material Sensitivity Testing",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS is experience in ABL and BAM friction testing, Modified Bureau of Mines Impact testing, and Electrostatic Static Discharge testing."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.material-sensitivity {
  .link {
    color: #aa222c;
  }
}
</style>
